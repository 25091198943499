<style lang="less" scoped>
@import "./SearchUnit.less";
</style>
<template>
  <div>
    <!-- search unit -->
    <div class="search-con search-con-top">
      <h3>按大学科目检索</h3>
      <br />
      <!-- 第一个选择器  选择学校  -->

      <Row>
        <Select
          class="filter-selector"
          v-model="campus"
          disabled
          placeholder="请选择学校"
        >
          <Option
            v-for="item in campusList"
            :value="item.value"
            :key="item.value"
            >{{ item.value }}</Option
          >
        </Select>
        <!-- 第二个选择器  选择学院  -->
        <Select
          class="filter-selector"
          v-model="faculty"
          placeholder="请选择学院"
          @on-change="fetchUnits"
        >
          <Option
            v-for="item in facultyList"
            :value="item.value"
            :key="item.value"
            >{{ item.value }}</Option
          >
        </Select>
        <!-- 第三个选择器  选择的科目 -->
        <Select
          class="filter-multi-selector"
          v-model="selectedUnits"
          placeholder="请输入科目代码"
          multiple
          @on-change="updateSelectedUnits"
          filterable
        >
          <Option
            v-for="(item, index) in unitsList"
            :value="item.value"
            :key="item.value"
            :label="item.value"
          >
            <span>
              {{ item.value }}
            </span>
            <span style="float: right; color: #ccc; max-width: 200px">
              {{ unitInfoList[index] }}
            </span>
          </Option>
        </Select>
        <Button type="primary" size="default" @click="handleShowAdvanced">
          更多
        </Button>
      </Row>
      <div v-show="showAdvanced">
        <br />
        <h3>
          按课程类型筛选
          <Checkbox
            :indeterminate="indeterminate"
            :value="checkAll"
            @click.prevent.native="handleCheckAllTypes"
            >全选
          </Checkbox>
        </h3>
        <br />
        <CheckboxGroup v-model="selectedTypes" @on-change="updateSelectedTypes">
          <Checkbox
            v-for="(type_name, type_code) in courseTypes"
            :label="type_code"
            :value="type_code"
            :key="type_code"
            border
            >{{ type_name }}
          </Checkbox>
        </CheckboxGroup>
      </div>
      <!-- <Button @click="handleSearch" class="search-btn" type="primary"><Icon type="search"/>&nbsp;&nbsp;搜索</Button>  -->
    </div>
    <Table
      border
      ref="selection"
      :columns="columns4"
      :data="tableData"
      :loading="loading"
    >
      <template slot-scope="{ row }" slot="name">
        <strong>{{ row.name }}</strong>
      </template>
      <template slot-scope="{ row }" slot="action">
        <Button
          type="info"
          size="small"
          style="margin-righ t: 5px;"
          @click="show(row)"
          >查看详情</Button
        >
      </template>
      <template slot-scope="{ row }" slot="selectUnit">
        <Button
          type="success"
          size="small"
          style="margin-right: 5px"
          @click="showConfirm(row)"
        >
          <Icon type="ios-cart" />
        </Button>
      </template>
    </Table>
    <div style="margin: 10px; overflow: hidden; float: right">
      <div style="display: flex; justify-content: space-between">
        <Page
          :total="totalCourseCount"
          :current="1"
          @on-change="changePage"
        ></Page>
      </div>
    </div>
    <Modal v-model="showCourseDetail" class="show-course-detail">
      <div class="ivu-modal-confirm-head">
        <div
          class="ivu-modal-confirm-head-icon ivu-modal-confirm-head-icon-info"
        >
          <i class="ivu-icon ivu-icon-ios-information-circle"></i>
        </div>
        <div class="ivu-modal-confirm-head-title">
          <h2>{{ showCourseDetailInfo.name }}</h2>
        </div>
      </div>
      <div class="ivu-modal-confirm-body">
        <div>
          <p class="show-course-detail">
            <b>学校：</b>{{ showCourseDetailInfo.campus }}
          </p>
          <p class="show-course-detail">
            <b>专业：</b>{{ showCourseDetailInfo.faculty }}
          </p>
          <p class="show-course-detail">
            <b>介绍：</b>
            <mavon-editor
              style="z-index: 1; width = 300px"
              v-if="showCourseDetail == true"
              v-model="showCourseDetailInfo.description"
              :subfield="descViewOption.subfield"
              :defaultOpen="descViewOption.defaultOpen"
              :toolbarsFlag="descViewOption.toolbarsFlag"
              :editable="descViewOption.editable"
              :scrollStyle="descViewOption.scrollStyle"
              :boxShadow="descViewOption.boxShadow"
              :previewBackground="descViewOption.previewBackground"
              :ishljs="true"
              :codeStyle="descViewOption.codeStyle"
            ></mavon-editor>
          </p>
        </div>
      </div>
    </Modal>

    <Modal
      v-model="confirmCourseDetail"
      @on-ok="confirmCourse()"
      class="confirm-detail"
    >
      <div class="ivu-modal-confirm-head">
        <div
          class="ivu-modal-confirm-head-icon ivu-modal-confirm-head-icon-warning"
        >
          <i class="ivu-icon ivu-icon-ios-alert"></i>
        </div>
        <div class="ivu-modal-confirm-head-title">
          <h2>确认报名 {{ confirmCourseDetailInfo.course_name }}</h2>
        </div>
      </div>

      <div class="ivu-modal-confirm-body">
        <div>
          <p class="confirm-detail">
            <b>学校：</b>{{ confirmCourseDetailInfo.course_campus }}
          </p>
          <p class="confirm-detail">
            <b>学院：</b>{{ confirmCourseDetailInfo.course_faculty }}
          </p>
          <p class="confirm-detail">
            <b>老师：</b>{{ confirmCourseDetailInfo.teacher }}
          </p>
          <p class="confirm-detail">
            <b>价格：</b>$ {{ confirmCourseDetailInfo.price }}
          </p>
          <p class="confirm-detail">
            <b>介绍：</b>
            <mavon-editor
              style="z-index: 1; width = 300px"
              v-if="confirmCourseDetail == true"
              v-model="confirmCourseDetailInfo.description"
              :subfield="descViewOption.subfield"
              :defaultOpen="descViewOption.defaultOpen"
              :toolbarsFlag="descViewOption.toolbarsFlag"
              :editable="descViewOption.editable"
              :scrollStyle="descViewOption.scrollStyle"
              :boxShadow="descViewOption.boxShadow"
              :previewBackground="descViewOption.previewBackground"
              :ishljs="true"
              :codeStyle="descViewOption.codeStyle"
            ></mavon-editor>
          </p>
        </div>
      </div>
    </Modal>

    <Modal v-model="modal1" class-name="aggrement-modal">
      <p slot="header">
        Are you sure to purchase this course and agree the refund policy?
      </p>
      <div>
        <Scroll>
          <b>REFUND POLICY</b>
          <hr />
          <br />
          <p>
            This Refund Policy ("Policy") applies to the following purchases:
            www.aucourse.com
          </p>
          <br />
          <p>
            (1) CUSTOMER SATISFACTION IS OUR PRIORITY At ________, customer
            satisfaction is our priority. We offer refunds, repairs and
            replacements in accordance with the Australian Consumer Law and on
            the terms set out in this Policy. Any benefits set out in this
            Policy may apply in addition to consumer's rights under the
            Australian Consumer Law. Please read this Policy before making a
            purchase, so that you understand your rights as well as what you can
            expect from us in the event that you are not happy with your
            purchase.
          </p>
          <br />
          <p>(2) AUSTRALIAN CONSUMER LAW</p>
          <p>
            (a) Under the Australian Consumer Law: Our goods and services come
            with guarantees that cannot be excluded under the Australian
            Consumer Law. For major failures with the service, you are entitled:
            - to cancel your service contract with us; and - to a refund for the
            unused portion, or to compensation for its reduced value. You are
            also entitled to choose a refund or replacement for major failures
            with goods. If a failure with the goods or a service does not amount
            to a major failure, you are entitled to have the failure rectified
            in a reasonable time. If this is not done you are entitled to a
            refund for the goods and to cancel the contract for the service and
            obtain a refund of any unused portion. You are also entitled to be
            compensated for any other reasonably foreseeable loss or damage from
            a failure in the goods or service.
          </p>
          <p>
            (b) We offer refunds, repairs, and replacements in accordance with
            the Australian Consumer Law.
          </p>
          <p>
            (c) The Australian Consumer Law provides a set of Consumer
            Guarantees which protect consumers when they buy products and
            services.
          </p>
          <p>
            (d) If the Australian Consumer Law applies, then we cannot avoid the
            Consumer Guarantees which it provides. If there is an inconsistency
            between this Policy and the Australian Consumer Law, the Australian
            Consumer Law will prevail.
          </p>
          <p>
            (e) Further information about the Australian Consumer Law and these
            Consumer Guarantees is available from the website of the Australian
            Competition and Consumer Commission.
          </p>
          <p>
            (f) If a product or service which you purchased from us has a major
            failure (as defined in the Australian Consumer Law) then you may be
            entitled to a replacement or refund. You may also be entitled to
            compensation for any reasonably foreseeable loss or damage resulting
            from that major failure.
          </p>
          <p>
            (g) If a product or service which you purchased from us has a
            failure which does not amount to a major failure (as defined in the
            Australian Consumer Law) then you may still be entitled to have the
            goods repaired or replaced.
          </p>
          <br />
          <p>
            (3) CHANGE OF MIND We do not offer any refund if you simply change
            your mind, or find the same product or service cheaper elsewhere.
          </p>
          <br />
          <p>(4) OTHER RETURNS</p>
          <p>
            (a) In addition, we may offer returns in the following
            circumstances: Any courses within 2 weeks of the date of purchase,
            upon presentation of proof of purchase.
          </p>
          <p>
            (b) In order to be eligible for a return under these circumstances,
            you must: provide the feedback and reasons why you have to refund
            the product and the products should not be assignment's tutoring
          </p>
          <p>
            (c) Any claims for a return under this clause must be made within
            the following time from the date you received the product: 2 weeks
          </p>
          <br />
          <p>
            (5) EXCEPTIONS Notwithstanding the other provisions of this Policy,
            we may refuse to provide a repair, replacement or refund for a
            product or service you purchased if:
          </p>
          <p>
            (a) You misused the said product in a way which caused the problem.
          </p>
          <p>
            (b) You knew or were made aware of the problem(s) with the product
            or service before you purchased it.
          </p>
          <p>
            (c) You asked for a service to be done in a certain manner, or you
            asked for alterations to a product, against our advice, or you were
            unclear about what you wanted.
          </p>
          <p>
            (d) Any other exceptions apply under the Australian Consumer Law.
          </p>
          <br />
          <p>(6) SHIPPING COSTS FOR RETURNS</p>
          <p>
            (a) In the event that a product you purchased fails to meet one or
            more Consumer Guarantees under the Australian Consumer Law we will
            bear any costs of shipping the said product (the "Returned Product")
            back to us, as well as any costs of shipping any replacement product
            to you.
          </p>
          <p>
            (b) If the Returned Product can easily be posted or returned, then
            you are responsible for organising for the Returned Product to be
            returned to us. If the Returned Product is eligible for a repair,
            replacement or refund under the terms of this Policy (including
            under the Australian Consumer Law) then we will reimburse you for
            the reasonable postage, shipping or transportation costs for the
            Returned Product.
          </p>
          <p>
            (c) If the Returned Product is too large, too heavy, or otherwise
            too difficult to be removed and returned by you, and is believed to
            be eligible for a repair, replacement or refund under the terms of
            this Policy (including under the Australian Consumer Law), then we
            will organise for the postage, shipping, transportation or
            collection of the Returned Product, at our cost.
          </p>
          <p>
            (d) In the event that we organise and pay for the inspection,
            postage, shipping, transportation or collection of a Returned
            Product, and it turns out not to be eligible for a repair,
            replacement or refund under the terms of this Policy (including
            under the Australian Consumer Law), then you will be required to pay
            the costs of any inspection, postage, shipping, transportation or
            collection of the Returned Product.
          </p>
          <br />
          <p>
            (7) PRODUCTS CONTAINING YOUR DATA In some cases, products that you
            return may contain your data. The replacement or repair of these
            products may result in loss of your data. We recommend that you back
            up any data to avoid data loss. We recommend that you remove
            sensitive or confidential data as this data may be accessible by
            anybody who assesses or repairs your products. We are not
            responsible for any data which is lost as a result of the repair or
            replacement of your products.
          </p>
          <br />
          <p>
            (8) REFURBISHED PRODUCTS Goods presented for repair may be replaced
            by refurbished goods of the same type rather than being repaired.
            Refurbished parts may be used to repair the goods.
          </p>
          <br />
          <p>(9) ASSISTANCE FROM MANUFACTURERS</p>
          <p>
            (a) In some cases, manufacturers may provide assistance in relation
            to their products, and they may be able to resolve your issue more
            quickly.
          </p>
          <p>
            (b) In some cases, manufacturers may provide warranties for their
            products, which go beyond the Consumer Guarantees under the
            Australian Consumer Law or any other rights which you may have under
            this Policy.
          </p>
          <p>
            (c) You are not obliged to contact the manufacturer directly in
            order to seek a repair, replacement or refund. However, you may do
            so if you wish.
          </p>
          <br />
          <p>
            (10) RESPONSE TIME We aim to process any requests for repairs,
            replacements or refunds within 30 days of having received them.
          </p>
          <br />
          <p>(11) HOW TO RETURN PRODUCTS</p>
          <p>
            (a) You may contact us to discuss a return using the details at the
            end of this Policy.
          </p>
          <p>
            (b) We will pay any refunds in the same form as the original
            purchase or to the same account or credit card as was used to make
            the original purchase, unless otherwise determined in our sole
            discretion.
          </p>
          <p>
            (c) You must provide proof of purchase in order to be eligible for a
            refund, repair or replacement.
          </p>
          <p>
            (d) You may be required to present a government issued
            identification document in order to be eligible for a refund, repair
            or replacement.
          </p>
          <br />
          <p>
            (12) CONTACT US If you wish to speak to us about this Policy or
            about any refund, repairs or replacements, you may contact us at:
            customerservice@aucourse.com
          </p>
          <br />
          <b>保密原则与协议：</b>
          <p>
            您同意本课程仅供自己学习观看。如有分享账号，泄露视频，录屏下载传播等侵权行为，愿意承担一切法律后果与经济损失。
          </p>
          <br />
          <b>退款协议:</b>
          <p>
            1.
            如果您觉得课程质量不符合自己的预期，或者临时改变主意。所有平时班、全包班、保过班在报名的两周内全额退款。逾期不进行退款。
          </p>
          <p>
            2.
            任何形式的作业班在开始上课30分钟后不提供退款，课程进行30分钟以内全额退款。
          </p>
          <p>3. 如果您认为一对一课程服务毫无作用并得到证实，全额退款。</p>
          <p>
            4.
            您在购买一对一课程服务后需要保持清晰的逻辑判断能力，课程1hr内认为老师讲的不好，课程质量不过关，需要及时向工作人员提出并终止课程。上课超出1hr或者课程已经结束，因为教学质量不佳补偿或退款请求将被拒绝。课程结束后您购买的视频录播课程一经购买，不提供退款。
          </p>
          <p>
            5.
            上课超出1hr或者课程已经结束，因为教学质量不佳补偿或退款请求将被拒绝。课程结束后您购买的视频录播课程一经购买，不提供退款。
          </p>
          <br />
          <b>特别注意事项：</b>
          <p>
            由于无法避免一些别有用心的用户泄漏视频，同时也无法控制学生抄袭的行为，尚学的课程极有可能出现大面积重复率过高的问题。为了避免不必要的麻烦，当视频观看数量超出合理范围时，尚学享保留关闭视频录播观看的权限。
          </p>
        </Scroll>
      </div>
      <div slot="footer">
        <Row type="flex" justify="space-between">
          <Col>
            <Checkbox v-model="agreement"><b>我同意此协议</b></Checkbox>
          </Col>
          <Col>
            <Button type="error" @click="modal1 = false">取消</Button>
            <Button
              :disabled="agreement ? false : true"
              type="success"
              @click="ok()"
              >确定</Button
            >
          </Col>
        </Row>
      </div>
    </Modal>
  </div>
</template>

<script>
// import moment from "moment";
import { Poptip } from "view-design";

export default {
  name: "searchUnit",
  data() {
    return {
      agreement: false,
      modal1: false,
      loading: true,
      rowinfo: null,
      unitsList: [],
      columns4: [
        // {
        //     type: 'selection',
        //     width: 60,
        //     align: 'center'
        // },
        {
          title: "开课学期",
          key: "semester",
          width: 100
        },
        {
          title: "课程名称",
          key: "course_name",
          sortable: true
        },
        {
          title: "价格",
          key: "price",
          minWidth: 220,
          render: (h, { row }) => {
            // console.log(row)
            if (row.original_price > row.price) {
              return (
                <span>
                  <Tag color="success" style="font-size:14px;">
                    现价: ${parseFloat(row.price).toFixed(2)}{" "}
                  </Tag>
                  <span style="fontSize: 12px; color: red">
                    <del>原价:${parseFloat(row.original_price).toFixed(2)}</del>
                  </span>
                </span>
              );
            } else {
              return (
                <span style="fontSize: 12px;">
                  原价:${parseFloat(row.original_price).toFixed(2)}
                </span>
              );
            }
          }
        },
        {
          title: "老师",
          key: "teacher",
          render: (h, { row }) => {
            return (
              <Poptip trigger="hover" placement="right" width="350" word-wrap>
                <Button type="primary" ghost>
                  {row.teacher.name}
                </Button>
                <div slot="content">
                  <div style="display: flex; flex-direction: column; justify-content: center;">
                    <div>
                      <Icon type="ios-contact" />
                      &nbsp;姓名&nbsp;:&nbsp;{row.teacher.name}
                    </div>
                    <div>
                      <Icon type="ios-school" />
                      &nbsp;课程&nbsp;:&nbsp;{row.teacher.courses}
                    </div>
                  </div>
                </div>
              </Poptip>
            );
          }
        },
        {
          title: "查看详情",
          slot: "action",
          width: 125,
          align: "center"
        },
        {
          title: "选择课程",
          slot: "selectUnit",
          width: 100,
          align: "center"
        }
      ],
      data1: [],
      selectedUnits: [],
      courseTypes: [],
      selectedTypes: [],
      tableData: [],
      showAdvanced: false,
      checkAll: false,
      indeterminate: false,
      campusList: [],
      faculty: "",
      facultyList: [],
      courses: [],
      selectedCourses: [],
      courseList: [],
      allCourseData: [],
      unitInfoList: [],
      teacherList: [],
      teacherDict: new Map(),
      currentPage: 1,
      totalCourseCount: 0,
      showCourseDetail: false,
      showCourseDetailInfo: {},
      descViewOption: {
        subfield: false, // 单双栏模式
        defaultOpen: "preview", //edit： 默认展示编辑区域 ， preview： 默认展示预览区域
        editable: false,
        toolbarsFlag: false,
        scrollStyle: true,
        boxShadow: false,
        previewBackground: "#fff",
        codeStyle: "atom-one-light",
        imageClick: false
      },
      confirmCourseDetail: false,
      confirmCourseDetailInfo: {}
    };
  },
  computed: {
    campus: function () {
      return this.$store.getters.getCurrentUser.campus;
    }
  },
  methods: {
    conductFetchCoursesParams() {
      let units = null;
      if (this.selectedUnits.length > 0) {
        units = this.selectedUnits.join(",");
      }
      let types = null;
      if (
        this.selectedTypes.length < Object.keys(this.courseTypes).length &&
        this.selectedTypes.length > 0
      ) {
        types = this.selectedTypes.join(",");
      }
      const page = this.currentPage;
      return {
        codes: units,
        page: page,
        types: types
      };
    },
    setupUnitList(rawUnitsList) {
      const pattern = /[a-zA-Z]+\d+/;
      // remove duplicated units
      const unitsSet = new Set();
      for (const course of rawUnitsList) {
        const match = pattern.exec(course);
        if (match != null) unitsSet.add(match[0]);
      }
      // set Unit list
      this.unitsList = [...unitsSet].map(course => ({
        value: course
      }));
      // clear selected units
      this.selectedUnits = [];
    },
    setUpUnitcode(rawUnitinfo) {
      this.unitInfoList = rawUnitinfo;
    },
    fetchCourses() {
      this.loading = true;
      this.axios
        .get(`api/campus/${this.campus}/faculty/${this.faculty}/courses`, {
          params: this.conductFetchCoursesParams()
        })
        .then(resp => {
          const rawData = resp.data;
          this.totalCourseCount = rawData.total_items;
          this.tableData = rawData.items;
          this.tableData.forEach(
            item => (item.teacher = this.teacherDict.get(item.teacher))
          );
          this.loading = false;
          // console.log(this.tableData);
        });
    },
    fetchUnits() {
      this.axios
        .get(`api/campus/${this.campus}/faculty/${this.faculty}/units`)
        .then(resp => {
          this.setupUnitList(Object.keys(resp.data));
          this.setUpUnitcode(Object.values(resp.data));
          this.fetchCourses();
        });
    },
    fetchFaculties() {
      this.axios.get(`api/campus/${this.campus}/faculties`).then(resp => {
        const faculties = resp.data;
        this.facultyList = faculties.map(faculty => ({ value: faculty }));
        this.faculty = faculties[0];
        this.fetchUnits();
      });
    },
    show(row) {
      this.showCourseDetail = true;
      this.showCourseDetailInfo.name = row.course_name;
      this.showCourseDetailInfo.campus = row.campus;
      this.showCourseDetailInfo.faculty = row.faculty;
      this.showCourseDetailInfo.description = row.description;
      // this.$Modal.info({
      //   title: `<h2>${row.course_name}</h2>`,
      //   // content: `Name：${this.data1[index].name}<br>Age：${this.data1[index].age}<br>Address：${this.data1[index].address}`
      //   content: `
      //                           <div style="display: flex; flex-direction:column; margin-top: 20px;">
      //                           <div style="font-size: 16px;margin: 6px 0;"><span style="font-weight: bold; ">学&nbsp;&nbsp;校:</span> &nbsp;&nbsp;${row.campus}</div>
      //                           <div style="font-size: 16px;margin: 6px 0;"><span style="font-weight: bold; ">学&nbsp;&nbsp;院:</span> &nbsp;&nbsp;${row.faculty}</div>
      //                           <div style="font-size: 16px;margin: 6px 0; "><span style="font-weight: bold; ">介&nbsp;&nbsp;绍:</span> &nbsp;&nbsp;
      //                             <div style="margin: 6px 0; word-break: break-all">${row.description}<br/></div>
      //                           </div>

      //                           </div>
      //                         `
      // });
    },
    showConfirm(row) {
      this.confirmCourseDetail = true;
      this.confirmCourseDetailInfo.course_name = row.course_name;
      this.confirmCourseDetailInfo.course_campus = row.campus;
      this.confirmCourseDetailInfo.course_faculty = row.faculty;
      this.confirmCourseDetailInfo.teacher = row.teacher.name;
      this.confirmCourseDetailInfo.price = row.price;
      this.confirmCourseDetailInfo.description = row.description;
      this.rowinfo = row;
      // this.$Modal.confirm({

      //   title: `确认报名${row.course_name}`,
      //   content: `
      //                       <div style="display: flex; flex-direction:column; margin-top: 20px;">
      //                       <div style="font-size: 16px;margin: 6px 0;"><span style="font-weight: bold; ">学&nbsp;&nbsp;校:</span> &nbsp;&nbsp;${
      //                         row.campus
      //                       }</div>
      //                       <div style="font-size: 16px;margin: 6px 0;"><span style="font-weight: bold; ">学&nbsp;&nbsp;院:</span> &nbsp;&nbsp;${
      //                         row.faculty
      //                       }</div>

      //                       <div style="font-size: 16px;margin: 6px 0; "><span style="font-weight: bold; ">老&nbsp;&nbsp;师:</span> &nbsp;&nbsp;${
      //                         row.teacher.display_name
      //                       }</div>
      //                       <div style="font-size: 16px;margin: 6px 0; "><span style="font-weight: bold; ">价&nbsp;&nbsp;格:</span> &nbsp;&nbsp;$${parseFloat(
      //                         row.price
      //                       ).toFixed(2)}</div>
      //                       <div style="font-size: 16px;margin: 6px 0; "><span style="font-weight: bold; ">描&nbsp;&nbsp;述:</span> &nbsp;&nbsp;
      //                         <div>${row.description}</div>
      //                       </div>
      //                       </div>
      //                       `,
      //   loading: true,
      //   onCancel: () => {
      //     this.agreement = false;
      //     this.$Modal.remove();
      //   },
      //   cancelText: "取消",
      //   onOk: () => {
      //     this.agreement = false;
      //     this.rowinfo = row;
      //     this.$Modal.remove();
      //     this.modal1 = true;
      //   },
      // });
    },

    confirmCourse() {
      this.agreement = false;
      this.modal1 = true;
    },

    async ok() {
      const { course_code, campus, faculty } = this.rowinfo;
      const selectedUnitInfo = JSON.stringify({
        course_code,
        campus,
        faculty
      });
      // console.log(selectedUnitInfo);
      try {
        const resp = await this.axios.post(
          `api/courses/signed`,
          selectedUnitInfo
        );
        // console.log(resp);
        this.$Message.success(
          `恭喜${resp.data.student}!您已经成功注册了${resp.data.course_code}。`
        );
        this.agreement = false;
        this.$router.push("/myorder");
      } catch (error) {
        this.modal1 = false;
        this.agreement = false;
        // console.log(error.response.status);
        const status = error.response.status;
        // console.log(status);
        if (status === 409) {
          // console.log("111");
          this.$Message.error("该课程已购买", error.response.status);
        } else {
          this.$Message.error("注册课程失败");
        }
      } finally {
        this.modal1 = false;
        this.agreement = false;
      }
    },
    selectUnit(row) {
      const { course_code, campus, faculty } = row;
      const selectedUnitInfo = JSON.stringify({ course_code, campus, faculty });
      this.axios
        .post(`api/courses/signed`, selectedUnitInfo)
        .then(resp => {
          this.$Message.success(
            `恭喜${resp.data.student}!您已经成功注册了${resp.data.course_code}。`
          );
        })
        .catch(this.$Message.error("注册课程失败"));
    },
    updateSelectedUnits() {
      this.currentPage = 1;
      this.fetchCourses();
    },
    handleShowAdvanced() {
      this.selectedTypes = [];
      this.checkAll = false;
      this.indeterminate = false;
      this.showAdvanced = !this.showAdvanced;
      if (!this.showAdvanced) this.fetchCourses();
    },
    handleCheckAllTypes() {
      if (this.indeterminate) {
        this.checkAll = false;
      } else {
        this.checkAll = !this.checkAll;
      }
      this.indeterminate = false;

      if (this.checkAll) {
        this.selectedTypes = Object.keys(this.courseTypes);
        this.currentPage = 1;
        this.fetchCourses();
      } else {
        this.selectedTypes = [];
        this.tableData = [];
      }
    },
    updateSelectedTypes() {
      const data = this.selectedTypes;
      if (data.length === 5) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
      this.currentPage = 1;
      this.fetchCourses();
    },
    changePage(currentPage) {
      this.currentPage = currentPage;
      this.fetchCourses();
    }
  },
  mounted: function () {
    this.axios
      .get(`api/teachers_courses`)
      .then(resp => {
        const fetchList = resp.data;
        const teacherMap = new Map();
        for (const each of fetchList) {
          const coursesStr = each.course_list
            .map(course => course.course_name)
            .join(", ");
          const details = {
            courses: coursesStr,
            name: each.teacher.display_name
          };
          teacherMap.set(each.teacher.username, details);
        }
        this.teacherDict = teacherMap;
        return this.axios.get("api/campuses");
      })
      .then(resp => {
        const campuses = resp.data;
        this.campusList = campuses.map(campus => ({ value: campus }));
        this.fetchFaculties();
        // Setup CourseTypes
        this.axios.get(`api/campus/${this.campus}/course_types`).then(resp => {
          this.courseTypes = resp.data;
        });
      });
    this.$Message.config({
      top: 50,
      duration: 3
    });
  }
};
</script>
